import React, { useEffect, useRef, useState } from 'react'
import { TopBar } from '../../Components/Sidebar/Sidebar'
import { BackButton } from '../../Components/Assets/BackButton'
import { RxSpeakerLoud, RxSpeakerOff } from 'react-icons/rx'
import { Slider } from 'primereact/slider'
import TableContainer from '../../Components/Assets/TableContainer'
import { ViewScreenShotsDialog } from './ViewScreenShotsDialog'
import { loader } from '../../utils/loader'
import { getPartData, getRecordingSessionJson } from '../../Services/Admin/Api'
import { useParams } from 'react-router-dom'
import moment from 'moment';
import { ViewScreenShots } from './ViewScreenShots'
import { CiPlay1 } from "react-icons/ci";
import { CiPause1 } from "react-icons/ci";
import { CiVolumeMute } from "react-icons/ci";

import { CiVolumeHigh } from "react-icons/ci";

export const ViewSessionPart = () => {
    // let [tableData, setTableData] = useState([])

    let { partId, sessionId } = useParams();
    let [sessionData, setSessionData] = useState({})
    let [partData, setPartData] = useState({})
    let [isVideoPaused, setIsVideoPaused] = useState(false)
    let [isVideoMuted, setIsVideoMuted] = useState(false)
    let [startTime, setStartTime] = useState(0)
    let [endTime, setEndTime] = useState(0)
    let video1Ref = useRef(null)
    let video2Ref = useRef(null)
    let video3Ref = useRef(null)
    let [errorData, setErrorData] = useState({})
    let scrollContainerRef = useRef(null)
    let [videoCurrentTime, setVideoCurrentTime] = useState(0)
    let [selectedKpi, setSelectedKpi] = useState("")
    let [currentVideoData, setCurrentVideoData] = useState({
        front: null,
        back: null,
        screen: null
    });

    let [isScreenShotPage, setIsScreenShotPage] = useState(false);

    useEffect(() => {
        getData()
        getJson()
    }, []);

    async function getData() {
        try {
            let body = {
                part_id: partId
            };
            let res = await getPartData(sessionId, body);
            let { session_parts, ...rest } = res.data.data
            console.log(res.data.data)
            let {
                frntCam: front,
                backCam: back,
                screen: screen } = rest
            setCurrentVideoData({
                front, back, screen
            })
            setSessionData(rest);
            setPartData(session_parts[0])

        }
        catch (err) {
            console.log(err);
        }
    }


    async function getJson() {
        try {
            loader.start();
            let body = {
                session: 1,
                part: 1
            };
            let res = await getRecordingSessionJson("667ac6341a4b215fbe61a00b", body);
            let dd = JSON.parse(res.data.data)
            setErrorData(dd)
            // let parsedData = JSON.parse(res.data.data);
            // console.log(parsedData);
        }
        catch (err) {
            loader.stop();
            console.log(err);
        }
        finally {
            loader.stop()
        }
    }

    function toggleVideo() {
        if (isVideoPaused) {
            video1Ref.current.play();
            video2Ref.current.play();
            video3Ref.current.play();
            setIsVideoPaused(false);
        }
        else {
            video1Ref.current.pause();
            video2Ref.current.pause();
            video3Ref.current.pause();
            setIsVideoPaused(true);
        }
    };

    function errorRowClick(data) {
        console.log(data)
        // if (data?.end_time_offset?.seconds < 180) {
        video1Ref.current.currentTime = data?.start_time_offset?.seconds
        video2Ref.current.currentTime = data?.start_time_offset?.seconds
        video3Ref.current.currentTime = data?.start_time_offset?.seconds
        scrollContainerRef.current.scrollTo({
            top: 0,
        });
        // }
    }

    function errorViewClick(data, kpi) {
        // if (data?.end_time_offset?.seconds < 180 && data?.start_time_offset?.seconds >= 0) {

        setStartTime(data?.start_time_offset?.seconds)
        setEndTime(data?.end_time_offset?.seconds)
        setIsScreenShotPage(true)
        setSelectedKpi(kpi)
        console.log(kpi)
        // }
    }
    function videoChange(e) {
        setVideoCurrentTime(video1Ref.current.currentTime)
    }
    function slideChange(e) {
        setVideoCurrentTime(e.value)
        video1Ref.current.currentTime = e.value
        video2Ref.current.currentTime = e.value
        video3Ref.current.currentTime = e.value
    }
    function muteToggle() {
        video1Ref.current.muted = !video1Ref.current.muted
        video2Ref.current.muted = !video2Ref.current.muted
        video3Ref.current.muted = !video3Ref.current.muted
        setIsVideoMuted(!isVideoMuted)
    }

    return (
        <div >
            <TopBar />

            <div ref={scrollContainerRef} style={{ height: "calc(100vh - 80px)", overflow: "auto" }}>

                {!isScreenShotPage ?
                    <div className="p-5">
                        <div className="card p-md-4 p-sm-3 p-2">
                            <BackButton link={`/recording/viewsession/${sessionId}`} />

                            <br />

                            <div className="row mt-4 border rounded p-3">
                                <div className="col-4">
                                    <video

                                        autoPlay
                                        onTimeUpdate={videoChange}
                                        ref={video1Ref}
                                        src={sessionData?.frntCam}
                                        style={{ width: "100%", maxHeight: '400px' }}
                                    ></video>
                                </div>
                                <div className="col-4">
                                    <video

                                        ref={video2Ref}
                                        src={sessionData?.backCam}
                                        style={{ width: "100%", maxHeight: '400px' }}
                                    ></video>
                                </div>
                                <div className="col-4">
                                    <video

                                        ref={video3Ref}
                                        src={sessionData?.screen}
                                        style={{ width: "100%", maxHeight: '400px' }}
                                    ></video>
                                </div>
                            </div>

                            {/* <div className="d-flex justify-content-center mt-3">
                                <button className='btn btn-primary px-4' onClick={toggleVideo}>{isVideoPaused ? "Play" : "Pause"}</button>
                            </div> */}
                            <br />

                            <div className='d-flex align-items-center gap-3'>
                                <div>
                                    {isVideoPaused ?
                                        <CiPlay1 className='pointer' onClick={toggleVideo} /> :
                                        <CiPause1 className='pointer' onClick={toggleVideo} />}
                                </div>
                                <div>
                                    {isVideoMuted ?
                                        <CiVolumeMute className='pointer' onClick={muteToggle} />
                                        :
                                        <CiVolumeHigh className='pointer' onClick={muteToggle} />
                                    }
                                </div>
                                <div className='w-100'>
                                    <Slider max={180} value={videoCurrentTime} onChange={slideChange} />
                                </div>
                            </div>



                            <div className="row mt-5">
                                <div className="col-xl-10 col-lg-8 col-12 row order-lg-1 order-2">
                                    <div className="col-xl-6 col-12 row text-nowrap m-0 p-0">
                                        <div className="col-6 mb-1">Evaluator Name:</div>
                                        <div className="col-6 mb-1 fw-bold">{sessionData?.userName?.[0]}</div>
                                        <div className="col-6 mb-1">Paper Name:</div>
                                        <div className="col-6 mb-1 fw-bold">{sessionData?.paperName}</div>
                                        <div className="col-6 mb-1">Session Part</div>
                                        <div className="col-6 mb-1 fw-bold">{partData?.part_number}</div>
                                        <div className="col-6 mb-1">Part Start Time:</div>
                                        <div className="col-6 mb-1 fw-bold">{partData?.part_start_time && moment(partData?.part_start_time).format("MM:SS")}</div>
                                    </div>
                                    {/* <div className="col-2"></div> */}
                                    <div className="col-xl-6 col-12 text-nowrap row  m-0 p-0">
                                        <div className="col-6 mb-1">Session Number:</div>
                                        <div className="col-6 mb-1 fw-bold">{sessionData?.session}</div>
                                        <div className="col-6 mb-1">Session Date</div>
                                        <div className="col-6 mb-1 fw-bold">{sessionData?.start_time && moment(sessionData?.start_time).format("DD-MM-YYYY")}</div>
                                        <div className="col-6 mb-1">Part Duration</div>
                                        <div className="col-6 mb-1 fw-bold">{partData?.duration && Math.floor(partData?.duration)} Sec</div>
                                        <div className="col-6 mb-1">Part End Time</div>
                                        <div className="col-6 mb-1 fw-bold">{partData?.part_end_time && moment(partData?.part_end_time).format("MM:SS") || "---"}</div>
                                    </div>

                                </div>
                                <div className="col-xl-2 col-lg-4 col-12 row order-lg-2 order-1 mb-lg-0 mb-4">
                                    <div className='col-lg-12 col-md-6 col-12  d-flex align-items-center gap-4 fs-4'>
                                        <RxSpeakerLoud />
                                        <div className='w-100'>
                                            <Slider />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h1 className='mt-4 mb-3'>Errors</h1>

                            <TableContainer>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>S No.</th>
                                            <th>Key Points of Interest</th>
                                            <th>Issue Start Time</th>
                                            <th>Issue End Time</th>
                                            <th>Issue Duration</th>
                                            <th>ScreenShots</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {Object.keys(errorData).map((res, ind1) => {
                                            return (
                                                <>
                                                    {errorData[res].map((res2, key) => {
                                                        return (
                                                            <tr >
                                                                <td>{(key + 1) * (ind1 + 1)}</td>
                                                                <td>{res}</td>
                                                                <td onClick={() => errorRowClick(res2, res)} className='pointer'>{res2?.start_time_offset?.seconds || "--"}</td>
                                                                <td>{res2?.end_time_offset?.seconds || "--"}</td>
                                                                <td>{res2?.end_time_offset?.seconds - res2?.start_time_offset?.seconds || "--"}</td>
                                                                <td onClick={() => errorViewClick(res2, res)} className='pointer'>View</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </TableContainer>


                        </div>
                    </div>
                    :
                    <>
                        {currentVideoData?.front &&
                            <ViewScreenShots
                                startTime={startTime}
                                endTime={endTime}
                                sessionData={sessionData}
                                interval={10}
                                setIsScreenShotPage={setIsScreenShotPage}
                                partData={partData}
                                selectedKpi={selectedKpi}
                                currentVideoData={currentVideoData} />}
                    </>
                }
            </div>


        </div>
    )
}
